import React, { Component } from 'react';
import QrReader from 'modern-react-qr-reader';

class QRCodeScanner extends Component {

	constructor(props){
		super(props)
		this.state = {
			delay: 300
		}

		this.handleError = this.handleError.bind(this);
		this.handleScan = this.handleScan.bind(this);
	}

	handleScan( data ){
		if ( null !== data )
		{
			var pieces = data.split( '/' ),
			ticket_number = pieces[pieces.length - 1];

			if ( 0 === ticket_number.indexOf( 'tk_' ) )
				this.props.ticketLookup( parseInt( ticket_number.replace( 'tk_', '' ) ) );
		}
	}

	handleError( err ){
		console.error( err );
	}

	render( ){
		return(
			<React.Fragment>
				<QrReader
					delay={this.state.delay}
					onError={this.handleError}
					onScan={this.handleScan}
					style={{ width: '320px' }}
					/>
			</React.Fragment>
		)
	}
}

export default QRCodeScanner;
