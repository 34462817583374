import React, { useState, useEffect } from 'react';
import './App.css';

import ScannerApp from './Unity/ScannerApp';

function App( ) {
	const [authenticated,setAuthenticated] = useState(false);

	const handleNoAuth = ( ) => { 
		localStorage.removeItem( 'authToken' );
		localStorage.removeItem( 'sessionDetails' );
		localStorage.removeItem( 'scanner' );

		window.location.replace( window.__main_domain ); 
	}

	const setupScanner = ( hash ) => {
		const scanner = localStorage.getItem( 'scanner' );
		if ( null === scanner || !scanner.hasOwnProperty( 'room_id' ) )
			fetch( 
				window.__rest_uri+'/v2/scanners?hash='+hash, 
				{ headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) } } 
			)
			.then( ( data ) => data.json( ) )
			.then( function( data ) {
				console.log( data );
				if ( 0 >= data.length || hash !== data[0].hash || !data[0].is_active )
					handleNoAuth( );
				else
					localStorage.setItem( 'scanner', JSON.stringify( data[0] ) );
			} )
			.catch( ( error ) => handleNoAuth( ) );
	};

	useEffect( ( ) => {
		const paths = window.location.pathname.split( '/' );

		if ( 2 <= paths.length && -1 === paths[1].indexOf( 'tk_' ) && 32 === paths[1].length )
		{
			const authToken = paths[1];
			localStorage.setItem( 'authToken', authToken );

			fetch( 
				window.__rest_uri+'/v2-get-user-session', 
				{ headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) } } 
				)
					.then( ( data ) => data.json( ) )
					.then( function( data ) {
						console.log( data );
						if ( data.hasOwnProperty( 'user' ) && data.permissions.includes( 'can_scan' ) )
						{
							localStorage.setItem( 'sessionDetails', JSON.stringify( data ) );
							const newUrl = window.location.href.replace( '/'+authToken, '' );
							window.location.replace( newUrl );
						}
						else
							handleNoAuth( );
					} )
					.catch( ( error ) => handleNoAuth( ) );
		}

		if ( null !== localStorage.getItem( 'sessionDetails' ) )
		{
			const sessionDetails = JSON.parse( localStorage.getItem( 'sessionDetails' ) );
			if ( sessionDetails.permissions.includes( 'can_scan' ) )
			{
				setupScanner( localStorage.getItem( 'authToken' ) );
				setAuthenticated( true );
			}
			else
				handleNoAuth( );
		}
	}, [ ] );

	return ( authenticated && <ScannerApp handleNoAuth={handleNoAuth} /> );
}

export default App;
